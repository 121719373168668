import React, { useEffect,useState } from 'react';
import router from './routes';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';


const SideBar = () => {
  const { token, user, isLogin } = useSelector((store) => store.auth);
  const [perms,setPerms] = useState([]);
  let location = useLocation();
  useEffect(() => {
    if (token) {
        setPerms(token.permissions)

    }
},[token])
  return (
    <div className="main-sidebar">
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          <a href="/">AYNN</a>
        </div>
        <div className="sidebar-brand sidebar-brand-sm">
          <a href="index.html">AYNN</a>
        </div>
        <ul className="sidebar-menu">
          {
            router.map(x => {
              if(x.is_header && x.visible){
                return(
                  <li className="menu-header">{x.name}</li>

                )
              }else if(!x.is_header && x.visible&& perms.indexOf(x.permission) !== -1){
                return(
                  <li className={x.path==location.pathname?"active":""}><Link className="nav-link" to={x.path}>{x.icon} <span>{x.name}</span></Link></li>

                )
              }
              

            })
          }
       
        </ul>

        
      </aside>
    </div>

  )
}

export default SideBar;
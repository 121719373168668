import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment'
import 'moment/locale/es';
import {
  Link
} from "react-router-dom";

const Empleos = (props) => {

  const lista_empleos = useSelector((store) => store.oft.empleos)
  const [lista, setLista] = useState([])
  const { token } = props
  useEffect(() => {
    setLista(lista_empleos)

  }, [lista_empleos])


  const time = (date) => {
    moment().locale('es')
    return moment(date, "YYYY-MM-DD hh:mm:ss").fromNow()
  }

  return (
    <>
      <div class="section-header">
        <h1>Lista de Ofertas</h1>
      </div>

      <div class="section-body">
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4>Listado</h4>
              {
                token.permissions.indexOf('empleo.add_empleo') !== -1 ? (
                  <Link to="/admin/crear-oferta-empleo" class="btn btn-success">Crear Oferta</Link>
                ) : (
                  null
                )
              }

            </div>


            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered table-md">
                <thead>

                  <tr>
                    <th>#</th>
                    <th>Titulo</th>
                    <th>Fecha Creacion</th>
                    <th>Estado</th>
                    {
                      token.permissions.indexOf('empleo.change_empleo') !== -1 ? (
                        <th>Acción</th>
                      ) : (
                        null
                      )
                    }
                  </tr>
                  </thead>
                  <tbody>

                  {
                    lista.map((empleo, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{empleo.cargo}</td>
                        <td>{empleo.fecha_publicacion}</td>
                        <td><div class="badge badge-success">{empleo.estado.descripcion}</div></td>
                        {
                          token.permissions.indexOf('empleo.change_empleo') !== -1 ? (
                            <td><Link to={`/admin/editar-oferta-empleo/${empleo.id_oferta_empleo}`} class="btn btn-secondary">Editar</Link></td>
                          ) : (
                            null
                          )
                        }
                      </tr>
                    ))
                  }
                  </tbody>

                </table>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default Empleos;
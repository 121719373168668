import Empleos from '../../pages/empleos/empleos'
import CrearEmpleo from '../../pages/empleos/crear-empleo'
import EditarEmpleo from '../../pages/empleos/editar-empleo'
import Postulantes from '../../pages/postulantes/postulantes';
import Usuarios  from '../../pages/usuarios/usuarios';
import CrearUsuario  from '../../pages/usuarios/crear-usuario';
import Correos from '../../pages/correos/correos'
/**
 * @global 
 * @description Rutas del panel de administración
*/
const route = [
    {
        'name':'Ofertas',
        'is_header':true,
        'visible':true

    },
    {
        'name':'Ofertas Empleo',
        'path':'/admin',
        'icon':<i class="fa fa-briefcase" aria-hidden="true"></i>,
        'component':Empleos,
        'is_header':false,
        'exact':true,
        'items':[],
        'visible':true,
        'permission':'empleo.view_empleo'


    },{
        'name':'Crear Oferta',
        'path':'/admin/crear-oferta-empleo',
        'icon':<i class="fa fa-briefcase" aria-hidden="true"></i>,
        'component':CrearEmpleo,
        'is_header':false,
        'exact':false,
        'items':[],
        'visible':false,
        'permission':'empleo.add_empleo'
    },
    {
        'name':'Editar Oferta',
        'path':'/admin/editar-oferta-empleo/:id',
        'icon':<i class="fa fa-briefcase" aria-hidden="true"></i>,
        'component':EditarEmpleo,
        'is_header':false,
        'exact':true,
        'items':[],
        'visible':false,
        'permission':'empleo.change_empleo'
    },
    {
        'name':'Postulaciones',
        'path':'/postulaciones',
        'icon':<i class="fas fa-tasks"></i>,
        'component':Postulantes,
        'is_header':false,
        'exact':true,
        'items':[],
        'visible':true,
        'permission':'empleo.view_postulante'
    },
    {
        'name':'Configuraciones',
        'is_header':true,
        'visible':true

    },
    {
        'name':'Usuarios',
        'path':'/usuarios',
        'icon':<i class="fas fa-users"></i>,
        'component':Usuarios,
        'is_header':false,
        'exact':true,
        'items':[],
        'visible':true,
        'permission':'auth.view_user'
    },
    {
        'name':'Crear Usuario',
        'path':'/usuarios/crear-usuario',
        'icon':<i class="fa fa-briefcase" aria-hidden="true"></i>,
        'component':CrearUsuario,
        'is_header':false,
        'exact':false,
        'items':[],
        'visible':false,
        'permission':'auth.add_user'
    },
    {
        'name':'Correo Contacto',
        'path':'/correo-contacto',
        'icon':<i class="fas fa-envelope-open-text"></i>,
        'component':Correos,
        'is_header':false,
        'exact':false,
        'items':[],
        'visible':true,
        'permission':'base.add_correocontacto'
    }

]

export default route;
import React, { useState,useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { sendResetPassword } from '../redux/auth';
import { useHistory, useParams } from "react-router-dom";

const RequestPasswordPage = () => {

    let history = useHistory();
    const dispatch = useDispatch();
    
    const reset_request = useSelector((store) => store.auth.reset_request);


    const [msj, setMsj] = useState({msj:'',type:''});

    const [email,setEmail] = useState('');

    useEffect(() => {
        if(reset_request==1){
            setMsj({msj: 'Ocurrio un error al procesar tu solicitud', type: 'danger'}); 

        }else if(reset_request==200){
            setMsj({msj: 'Ya estás recibiendo un correo de recuperación', type: 'success'}); 
        }
    }, [reset_request])


    


      const handleSubmit = (e) => {
        e.preventDefault()
        if (email!='') {
          dispatch(sendResetPassword(email));
        } else {
            setMsj({msj: 'Ingresa tu correo', type: 'danger'}); 
        }
      }

    return(
        <section class="section">
      <div class="container mt-5">
        <div class="row">
          <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="login-brand">
              <img src="#" alt="logo" width="100" class="shadow-light rounded-circle"/>
            </div>

            <div class="card card-primary">
              <div class="card-header"><h4>Reestablecer contraseña</h4></div>

              <div class="card-body">
              {
                msj ? (<div className={`alert alert-${msj.type} m-3`}>
                  {msj.msj}
                </div>) : (null)
              }
                <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                  

                  <div class="form-group">
                    <label for="password-confirm">Correo</label>
                    <input name="email" type="email" class="form-control" tabindex="2" required onChange={e => setEmail(e.target.value)}/>
                  </div>

                  <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-lg btn-block" tabindex="4" >
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="simple-footer">
              Copyright &copy; Innode 2021
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}

export default RequestPasswordPage;
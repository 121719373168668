import axios, { 
    URLAPI, EMPLEOS_ENDPOINT, 
    EMPLEOS_NOAUTH_ENDPOINT,ENVIAR_POSTULACION_ENDPOINT,
    POSTULANTES_ENDPOINT
} from '../config/index'


const initdata = {
    empleos: [],
    error: {
        status: 0,
        message: null,
    },
    edit_status: 0,
    create_status: 0,
    enviar_postulacion:0,
    postulantes:[]
}

/**@module Modulo Empleo */

/**@summary  Acciones para el reducer del modulo */



const EMPLEOS = "EMPLEOS";
const ERROR = "ERROR";
const EDITAR_EMPLEO = "EDITAR_EMPLEO";
const CREAR_EMPLEO = "CREAR_EMPLEO";
const EMPLEOS_NO_AUTH = "EMPLEOS_NO_AUTH";
const ENVIAR_POSTULACION = "ENVIAR_POSTULACION";
const POSTULANTES = 'POSTULANTES';


/**
 * @method  empleosModule
 * @sumary Reducer del modulo empleo
 * @param {JSONObject} state - estado inicial del modulo
 * @param {JSONObject} data - datos a cambiar en el estado
 * @returns {JSONObject} estado actualizado
 */
export const empleosModule = (state = initdata, data) => {

    switch (data.type) {
        case EMPLEOS:
            return { ...state, empleos: data.payload }
        case ERROR:
            return { ...state, error: data.payload }
        case EDITAR_EMPLEO:
            return { ...state, edit_status: data.payload }
        case CREAR_EMPLEO:
            return { ...state, create_status: data.payload }
        
        case EMPLEOS_NO_AUTH:
            return { ...state, empleos: data.payload }
        
        case ENVIAR_POSTULACION:
            return { ...state, enviar_postulacion: data.payload }
        
        case POSTULANTES:
            return { ...state, postulantes: data.payload }
        default:
            return { ...state }
    }
}


/**
 * @method getEmpleos
 * @sumary Realiza un peticion a la api para obtener una lista de las ofertas de empleo
 * @param {JSONObject} token -  objeto de autenticacion
 * @return {void}
 */
export const getEmpleos = (token) => async (dispatch) => {

    try {
        const response = await axios.get(URLAPI + EMPLEOS_ENDPOINT,{headers:{'Authorization':'Token '+token.token}});
        dispatch({ type: EMPLEOS, payload: response.data })
    } catch (error) {
        dispatch({
            type: ERROR,
            payload: { status: 1, message: 'No se pudo conectar' }
        });
    }

}

/**
 * @method editEmpleo
 * @sumary Realiza un peticion a la api para editar una oferta de empleo
 * @param {int} id -  id de la oferta de empleo
 * @param {JSONObject} data -  objeto con los datos de la oferta a editar
 * @param {JSONObject} token -  objeto de autenticacion
 * @return {void}
 */
export const editEmpleo = (id, data,token) => async (dispatch) => {
    try {
        const response = await axios.put(URLAPI + EMPLEOS_ENDPOINT + id, data,{headers:{'Authorization':'Token '+token.token}});
        dispatch({ type: EDITAR_EMPLEO, payload: response.status })
    } catch (error) {
        dispatch({
            type: EDITAR_EMPLEO,
            payload: 1
        });
    }
}


/**
 * @method crearEmpleo
 * @sumary Realiza un peticion a la api para crear una oferta de empleo
 * @param {JSONObject} data -  objeto con los datos de la oferta a crear
 * @param {JSONObject} token -  objeto de autenticacion
 * @return {void}
 */
export const crearEmpleo = (data,token) => async (dispatch) => {
    try {
        console.log(data)
        const response = await axios.post(URLAPI + EMPLEOS_ENDPOINT, data,{headers:{'Authorization':'Token '+token.token}});
        dispatch({ type: CREAR_EMPLEO, payload: response.status })
    } catch (error) {
        dispatch({
            type: CREAR_EMPLEO,
            payload: 1
        });
    }
}



export const editStatus = (status) => async (dispatch) => {
    dispatch({ type: EDITAR_EMPLEO, payload: status })
}

export const createStatus = (status) => async (dispatch) => {
    dispatch({ type: CREAR_EMPLEO, payload: status })
}


export const clearMsj = (type, valor) => async (dispatch) => {
    dispatch({ type: ERROR, payload: { status: type, message: valor } })
}


/**
 * @method getEmpleosNoAuth
 * @sumary Realiza un peticion a la api para obtener una lista de las ofertas de empleo sin estar autenticado
 * @param {String} fecha_publicacion -  fecha de publicacion de la oferta
 * @param {String} fecha_caducidad -   fecha de caducidad de la oferta
 * @return {void}
 */
export const getEmpleosNoAuth = (fecha_publicacion,fecha_caducidad)=> async (dispatch) => {
    try {
        const res = await axios.get(URLAPI + EMPLEOS_NOAUTH_ENDPOINT+'?fecha_publicacion='+fecha_publicacion+'&fecha_caducidad='+fecha_caducidad);
        dispatch({ type: EMPLEOS_NO_AUTH, payload: res.data })
    } catch (error) {
        dispatch({
            type: ERROR,
            payload: { status: 1, message: 'No se pudo conectar' }
        });
    }
}


/**
 * @method enviarPostulacion
 * @sumary Realiza un peticion a la api para enviar una postulacion
 * @param {String} fecha_publicacion -  fecha de publicacion de la oferta
 * @param {File} file -   Hoja de vida del postulante (.pdf,.docx,.png,.jpg,.jpeg)
 * @return {void}
 */
export const enviarPostulacion = (json,file) => async (dispatch) => {
    try {
        var data = new FormData();
        data.append('nombre', json.nombre);
        data.append('apellido', json.apellido);
        data.append('telefono', json.telefono);
        data.append('correo', json.correo);
        data.append('likedin', json.likedin);
        data.append('empleados_relacionados', json.empleados_relacionados);
        data.append('parentezco', json.parentezco);
        data.append('hoja_vida', file);
        data.append('id_empleo', json.id_empleo);
        const response = await axios.post(URLAPI + ENVIAR_POSTULACION_ENDPOINT, data);
        dispatch({ type: ENVIAR_POSTULACION, payload: response.status })
    } catch (error) {
        console.log(error);
        dispatch({
            type: ENVIAR_POSTULACION,
            payload: 1
        });
    }
}

/**
 * @method getPostulantes
 * @sumary Realiza un peticion a la api para obtener la lista de postulantes
 * @param {JSONObject} token -  objeto de autenticacion
 * @return {void}
 */
export const getPostulantes = (token) => async (dispatch) => {
    try {
        const response = await axios.get(URLAPI + POSTULANTES_ENDPOINT,{headers:{'Authorization':'Token '+token.token}});
        dispatch({ type: POSTULANTES, payload: response.data })
    } catch (error) {
        dispatch({
            type: ERROR,
            payload: { status: 1, message: 'No se pudo conectar' }
        });
    }

}

import Index from "./pages/index";
import Login from "./pages/login";
import Admin from "./pages/admin";
import ResetPasswordPage from "./pages/reset-password";
import RequestPasswordPage from "./pages/request-password";
import Politicas from "./pages/politicas";
import Terminos from "./pages/terminos";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function App() {
  const { token, user, isLogin } = useSelector((store) => store.auth);
  const [isAuth, setAuth] = useState(false);

  useEffect(() => {
    if (token && user && isLogin) {
      setAuth(true);
    }
  }, [token, user, isLogin]);

  return (
    <Router>
      <Switch>
        <Route path="/politicas">
          <Politicas />
        </Route>
        <Route path="/terminos">
          <Terminos />
        </Route>
        <Route exact path="/">
          <Index />
        </Route>
        {isAuth ? (
          <Route exact path="/admin">
            <Admin />
          </Route>
        ) : (
          <>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/reset-password/:uid/:token">
              <ResetPasswordPage />
            </Route>
            <Route exact path="/reset-password-email/">
              <RequestPasswordPage />
            </Route>
          </>
        )}

        <Route>
          <Index />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

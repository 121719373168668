import React, { useState,useEffect } from 'react'
import './login.css'
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../redux/auth';
import { useHistory, useParams } from "react-router-dom";
import {
  Link
} from "react-router-dom";

const Login = () => {

  const login_status = useSelector((store) => store.auth.login_status);
  const { token, user, isLogin } = useSelector((store) => store.auth);
  let history = useHistory();

  const [data, setData] = useState({
    username: '',
    password: ''
  })
  const [msj, setMsj] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (login_status==1) {
      setMsj('Ocurrio un error al iniciar sesion')
    }
    if (token && user && isLogin) {
      history.push('/admin');
    }
  }, [login_status,token,user,isLogin])

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { username, password } = data
    if (username && password) {
      dispatch(login(username, password));
    } else {
      setMsj('Ingresa tu usuario y contraseña')
    }
  }
  return (
    <section className="section">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="login-brand">
              <img src="#" alt="logo" width="100" className="shadow-light rounded-circle" />
            </div>

            <div className="card card-primary">
              <div className="card-header"><h4>Login</h4></div>
              {
                msj ? (<div className="alert alert-danger m-3">
                  {msj}
                </div>) : (null)
              }
              <div className="card-body">
                <form className="needs-validation" novalidate="" onSubmit={(e) => handleSubmit(e)}>
                  <div className="form-group">
                    <label for="email">Usuario</label>
                    <input id="email" type="username" className="form-control" onChange={e => handleChange(e)} name="username" tabindex="1" required autofocus />
                    <div className="invalid-feedback">
                      Ingresa tu nombre de usuario
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="d-block">
                      <label for="password" className="control-label">Contraseña</label>
                      <div className="float-right">
                        <Link  to="/reset-password-email" className="text-small">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <input id="password" type="password" className="form-control" onChange={e => handleChange(e)} name="password" tabindex="2" required />
                    <div className="invalid-feedback">
                      Ingresa tu contraseña
                    </div>
                  </div>

                 

                  <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-lg btn-block" tabindex="4">
                      Iniciar Sesión
                    </button>
                  </div>
                </form>
              </div>
            </div>
            
            <div className="simple-footer">
              Copyright &copy; Innode 2021
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Login
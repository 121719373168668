import React, { useState, useEffect } from "react";
import nav from "../img/nav-logo-1.png";

const Nav = () => {
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState("0px");
  useEffect(() => {
    if (show) {
      setWidth("250px");
    } else {
      setWidth("0px");
    }
  }, [show]);

  return (
    <nav
      className="navbar navbar-trans navbar-expand-md fixed-top p-5"
      id="mainNav"
      style={{ position: "fixed", backgroundColor: "white" }}
    >
      <div id="mySidenav" style={{ width: width }} class="sidenav">
        <a
          href="javascript:void(0)"
          onClick={() => setShow(!show)}
          class="closebtn"
        >
          &times;
        </a>
        <ul className="mt-2 mt-lg-0">
          <li className="nav-item">
            <a
              className="js-scroll active"
              style={{ color: "#024279" }}
              href="#home"
            >
              INICIO
            </a>
          </li>
          <li className="nav-item">
            <a
              className=" js-scroll"
              style={{ color: "#024279" }}
              href="#choose"
            >
              QUIÉNES SOMOS
            </a>
          </li>
          <li className="nav-item">
            <a
              className=" js-scroll"
              style={{ color: "#024279" }}
              href="#services"
            >
              VALORES ORGANIZACIONALES
            </a>
          </li>
          <li className="nav-item">
            <a className="js-scroll" style={{ color: "#024279" }} href="#works">
              OFERTA DE EMPLEO
            </a>
          </li>
          <li className="nav-item">
            <a
              className="js-scroll"
              style={{ color: "#024279" }}
              href="#contact"
            >
              CONTÁCTENOS
            </a>
          </li>
        </ul>
      </div>
      <div className="row" id="btn-show-menu">
        <div className="col-1">
          <span
            style={{ fontSize: "30px", cursor: "pointer",marginLeft:-25,marginBottom:10 }}
            onClick={() => setShow(!show)}
          >
            &#9776;
          </span>
        </div>
        <div className="col-11">
          <a className="navbar-brand" href="#">
            <img src={nav} width={250} style={{marginTop:-90,marginLeft:25}}/>
          </a>
        </div>
      </div>

      <div className="container" id="menu-superior">
        <a className="navbar-brand js-scroll mt-3" href="#">
          <img src={nav} width={250} />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {" "}
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div
          className="collapse navbar-collapse  justify-content-end"
          id="navbarTogglerDemo01"
        >
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className="nav-item">
              <a
                className="nav-link js-scroll active"
                style={{ color: "#024279", fontWeight: "bold" }}
                href="#home"
              >
                INICIO
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link js-scroll"
                style={{ color: "#024279", fontWeight: "bold" }}
                href="#choose"
              >
                QUIÉNES SOMOS
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link js-scroll"
                style={{ color: "#024279", fontWeight: "bold" }}
                href="#services"
              >
                VALORES ORGANIZACIONALES
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link js-scroll"
                style={{ color: "#024279", fontWeight: "bold" }}
                href="#works"
              >
                OFERTA DE EMPLEO
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link js-scroll"
                style={{ color: "#024279", fontWeight: "bold" }}
                href="#contact"
              >
                CONTÁCTENOS
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment'
import 'moment/locale/es';
import {
  Link
} from "react-router-dom";
import useScript from '../../components/useScript';

const Usuarios = (props) => {

  const usuarios = useSelector((state) => state.bases.usuarios);
  const [data, setData] = useState(usuarios)
  const { token } = props


  useEffect(() => {
    setData(usuarios)
  }, [usuarios])

  return (
    <>
      <div class="section-header">
        <h1>Lista de Usuarios</h1>
      </div>

      <div class="section-body">
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4>Listado</h4>
              <br />
              {
                token.permissions.indexOf('auth.add_user') !== -1 ? (
                  <Link to="/usuarios/crear-usuario" class="btn btn-success">Crear Usuario</Link>
                ) : (
                  null
                )
              }
            </div>


            <div class="card-body">

              <div class="table-responsive">

                <table class="table table-bordered table-md" id="table">
                  <thead>

                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Apellido</th>
                      <th>Usuario</th>
                      <th>Correo</th>

                    </tr>
                  </thead>
                  <tbody>

                    {
                      data.map((user, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{user.first_name}</td>
                          <td>{user.last_name}</td>
                          <td>{user.username}</td>
                          <td>{user.email}</td>

                        </tr>
                      ))
                    }
                  </tbody>



                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Usuarios;

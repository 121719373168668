import axios, {LOGIN_ENDPOINT,URLAPI, LOGOUT_ENDPOINT,PASSWORD_CONFIRM,PASSWORD_RESET} from '../config/index'


const init = {
    token: {
        token:'',
        user_id: '',
        email: '',
        fecha:'',
        permissions:[]

    },
    user: null,
    isLogin:false,
    login_status:0,
    reset_confirm:0,
    reset_request:0
}


/**@module Modulo de Autenticación**/
const LOGIN = 'LOGIN';
const ERROR = 'ERROR';
const LOGOUT = 'LOGOUT';
const RESET_PASSWORD = 'RESET_PASSWORD';
const PASSWORD_CONFIRM_ = 'PASSWORD_CONFIRM_';

/**
 * @method  authModule
 * @sumary Reducer del modulo base
 * @param {JSONObject} state - estado inicial del modulo
 * @param {JSONObject} data - datos a cambiar en el estado
 * @returns {JSONObject} estado actualizado
 */
export const authModule = (state = init, data) => {

    switch (data.type) {
        case LOGIN:
            return {...state, token:data.payload.token, user:data.payload.user, isLogin:data.payload.isLogin}

        case ERROR:
            return {...state, login_status:data.payload}
        
        case LOGOUT:
            return init
        
        case PASSWORD_CONFIRM_:
            return {...state, reset_confirm:data.payload}
        
        case RESET_PASSWORD:
            return {...state, reset_request:data.payload}
        
        default:
            return state;
    }
}

/**
 * @method login
 * @sumary Realiza un peticion a la api para iniciar sesion, guarda el token y y los datos del usuario
 * dentro del estado de la aplicacion
 * @param {String} username -  Nombre de usuario
 * @param {String} password -  Contraseña del usuario
 * @return {void}
 */

export const login = (username, password) => async (dispatch) =>{
    try {
         console.log({username,password})
         const res = await axios.post(URLAPI+LOGIN_ENDPOINT,{username, password})
         dispatch({type:LOGIN, payload:{token:res.data, user:username, isLogin:true}})
    }catch(e) {
        console.error(e.type,e.message)
        dispatch({type:ERROR, payload:1})
    }
}

/**
 * @method logout
 * @sumary Realiza un peticion a la api para cerrar sesion, dejando el estado de la aplicacion al estado inicial
 * @param {JSONObject} token -  objecto con el token de sesion
 * @return {void}
 */
export const logout = (token) => async (dispatch) =>{
    try {

        await axios.post(URLAPI+LOGOUT_ENDPOINT,{},{headers:{'Authorization':'Token '+token.token}})
        dispatch({type:LOGOUT, payload:1})
    }catch(e) {
        dispatch({type:ERROR, payload:1})
    }
}


/**
 * @method resetPassword
 * @sumary Realiza un peticion a la api para recuperar la contraseña. recibe los datos que se envia al correo del usuario
 * @param {JSONObject} data -  oobjeto con los datos {uid: '', token: '', new_password1: '', new_password2: ''}
 * @return {void}
 */
export const resetPassword = (data) => async (dispatch) =>{
    try {
        const res = await axios.post(URLAPI+PASSWORD_CONFIRM,data)
        dispatch({type:PASSWORD_CONFIRM_, payload:res.status})
    }catch(e) {
        dispatch({type:PASSWORD_CONFIRM_, payload:1})
    }
}


/**
 * @method sendResetPassword
 * @sumary Realiza un peticion a la api para realizar una solicitud de recuperacion de la contraseña. si el envio del correo fue exitoso 
 * envia la accion RESET_PASSWORD con el valor del estado de la peticion (200)
 * @param {JSONObject} data -  oobjeto con los datos {uid: '', token: '', new_password1: '', new_password2: ''}
 * @return {void}
 */
export const sendResetPassword = (email) => async (dispatch) =>{
    try {
        const res = await axios.post(URLAPI+PASSWORD_RESET,{email})
        dispatch({type:RESET_PASSWORD, payload:res.status})
    }catch(e) {
        dispatch({type:RESET_PASSWORD, payload:1})
    }
}
import React,{useEffect} from "react";
import Nav from "../components/main/nav";
import { Link } from "react-router-dom";
const Terminos = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Nav />
      <div className="container mt-5 pt-5" id="section">
        <div className="row mt-4   ">
          <div className="col-md-12">
            <h4>Términos y condiciones</h4>
          </div>

          <div className="col-md-3 col-sm-12 col-xs-12 mt-4">
            <ul class="list-group">
              <li class="list-group-item">
                {" "}
                <Link
                  to="/politicas"
                  style={{
                    color: "black",
                  }}
                >
                  Política tratamiento de datos
                </Link>
              </li>
              <li
                class="list-group-item"
                style={{
                  backgroundColor: "rgb(2, 66, 121)",
                  color: "#fff",
                }}
              >
                <Link
                  to="/terminos"
                  style={{
                    color: "#fff",
                  }}
                >
                  Términos y condiciones
                </Link>
              </li>
            </ul>
          </div>

          <div className="col mt-4 card">
            <p class="text-justify">
              <b>Aceptación de términos y condiciones</b>
              <br />
              <br />
              Al navegar o usar el sitio web corporativo de<b> Administración y
              Negocios Nutibara S.A.S </b>, nuestros usuarios admiten haber leído,
              comprendido y se encuentran de acuerdo con la política de
              privacidad de datos, incluyendo términos y condiciones publicados
              en nuestro sitio web, así mismo, acepta cumplir las leyes,
              políticas y demás reglamentos aplicables a los servicios prestados
              por la empresa, los cuales están basados en las legislaciones
              colombianas. En caso de no estar de acuerdo, solicitamos por favor
              abstenerse a navegar y usar el presente sitio web. Este sitio fue
              diseñado y es operado por Administración y negocios Nutibara
              S.A.S, su objetivo principal es entregar a sus clientes
              información sobre los servicios de outsourcing prestados a sus
              diferentes grupos de interés, así mismo dar a conocer nuestra
              filosofía organizacional y proporcional un espacio ameno de
              interacción con la empresa. Este sitio web, es un sitio público y
              de carácter digital, el cual pretende facilitar servicios
              informativos y de contenidos sobre la empresa. Es importante
              aclarar que el sitio puede contener vínculos a otros sitios, los
              cuales no son controlados bajo las mismas políticas de
              Administración y Negocios Nutibara S.A.S; por tal motivo la
              empresa no se hace responsable de los contenidos, cambios o
              actualizaciones que puedan realizarse en dichos sitios (terceros).
              <br />
              <br />
              <ul>
                <li>
                  <b>
                    <u>Aviso de privacidad</u>
                  </b>
                  <br />
                  <ol
                    style={{
                      listStyle: "circle",
                    }}
                    className="pl-5"
                  >
                    <li>
                      Señor usuario, en calidad de visitante de nuestro sitio
                      web, usted no debe aportar ningún tipo de datos sensible.
                      Nuestro sitio solo recopila información personal
                      especifica tales como: Nombre y e-mail, los cuales son
                      proporcionados directamente por el usuario, en caso de
                      querer establecer algún tipo de comunicación con la
                      empresa. Es importante tener en cuenta, que la empresa no
                      solicitará en ningún caso datos sensibles, si resulta
                      necesario la empresa siempre solicitará autorización
                      previa.
                    </li>
                    <li>
                      En cumplimiento de la ley Estatutaria 1581 de 2012,
                      mediante el presente Aviso de privacidad,
                      <b> Administración y Negocios Nutibara S.A.S </b>será el
                      responsable del tratamiento de datos personales de los
                      usuarios llevando a cabo el cumplimiento de todas las
                      disposiciones legales mediante el empleo de medidas de
                      seguridad sobre la información suministrada que garantizan
                      la integridad de los datos.
                    </li>
                    <li>
                      El usuario tendrá derecho a conocer, actualizar y
                      rectificar sus datos personales, ser informado sobre el
                      uso que hemos dado a los mismos, presentar quejas ante la
                      SIC por infracción a la ley, revocar la autorización y/o
                      solicitar la supresión de sus datos en los casos en que
                      sea procedente y acceder en forma gratuita a los mismos,
                      mediante un correo dirigido a
                      tratamientodedatos@negociosnutibara.com indicando en el
                      asunto, el derecho que desea ejercitar o mediante correo
                      certificado remitido a la dirección: carrera 50 # 52-
                      96_Antioquia.
                    </li>
                  </ol>
                </li>
                <li className="mt-5">
                  <b>
                    <u> Transferencia de datos personales </u>
                  </b>
                  <br />
                  <ol
                    style={{
                      listStyle: "circle",
                    }}
                    className="pl-5"
                  >
                    <li>
                      Cumpliendo lo dispuesto con la normatividad colombiana
                      (ley de tratamiento de datos 1581 de 2012) la empresa no
                      transfiere ningún tipo de dato a terceros que no cumplan
                      con las exigencias planteadas por la superintendencia de
                      Industria y comercio o salvo aquellas excepciones que por
                      ley así lo requieran.
                    </li>
                  </ol>
                </li>
                <li className="mt-5">
                  <b>
                    <u> Divulgación de información a terceros.</u>
                  </b>
                  <br />
                  <ol
                    style={{
                      listStyle: "circle",
                    }}
                    className="pl-5"
                  >
                    <li>
                      La información que fuere recolectada de los usuarios del
                      sitio web, no será transmitida, salvo sea solicitada por
                      autoridades como: policiales, entes gubernamentales o
                      reguladoras, esto con el fin de dar cumplimiento al
                      tratamiento de los datos.
                    </li>
                    <li>
                      Si el usuario proporciona información a través de este
                      sitio web, indicaría la aceptación de las condiciones
                      dadas para la divulgación de información según lo descrito
                      anteriormente.
                    </li>
                  </ol>
                </li>
                <li className="mt-5">
                  <b>
                    <u> Cambios a nuestro Aviso de privacidad</u>
                  </b>
                  <br />
                  <ol
                    style={{
                      listStyle: "circle",
                    }}
                    className="pl-5"
                  >
                    <li>
                      La empresa podrá modificar el aviso de privacidad del
                      sitio web, según las necesidades generadas, asegurando al
                      usuario que dichas modificaciones siempre están
                      direccionadas a la protección de información. Le
                      recomendamos revisar periódicamente este aviso de
                      privacidad, con el fin de que pueda mantenerse actualizado
                      con los cambios generados, según el caso.
                    </li>
                  </ol>
                </li>
                <li class="text-center mt-5">
                  <b>Autorización de tratamiento de datos </b>
                </li>
                <li>
                  Es importante para nosotros conocer si en tu calidad de
                  cliente, proveedor y/o usuario autorizas a Administración y
                  Negocios Nutibara S.A.S para establecer comunicación a través
                  de los diferentes medios suministrados ya sea directamente o a
                  través de terceros. Según lo dispuesto en la normatividad 1581
                  de 2012, si es aceptado el tratamiento de datos incluye:
                  <ol
                    style={{
                      listStyle: "circle",
                    }}
                    className="pl-5"
                  >
                    <li>
                      Contactar al cliente, proveedor y/o usuario, con el fin de
                      conocer su opinión sobre nuestros servicios.
                    </li>
                    <li>
                      Tratar los datos personales almacenados para ofrecer
                      servicios de outsourcing.
                    </li>
                    <li>
                      Realizar verificaciones en diferentes fuentes públicas con
                      el fin de minimizar el riesgo contractual.
                    </li>
                    <li>
                      Almacenar, consultar, compartir, verificar, reportar,
                      actualizar y/o rectificar los datos personales al interior
                      de Administración y negocios Nutibara S.A.S o ante
                      cualquier otra entidad nacional o extranjera que
                      administre base de datos, preste servicios de verificación
                      o realice análisis de administración de riesgos.
                    </li>
                    <li>
                      Almacenar y tratar de manera segura, la información que es
                      recolectada, almacenada, organizada, usada, circulada,
                      transmitida, transferida, actualizada, rectificada, para
                      proteger su información contra perdida, abuso, acceso o
                      uso no autorizado o fraudulento, tomando las medidas de
                      precaución necesarias para proteger dicha información.
                    </li>
                  </ol>
                </li>
                <li class="mt-5">
                  <b class="text-center">
                    <u>Atención de Peticiones.</u>
                  </b>
                  <br />
                  <ol
                    style={{
                      listStyle: "circle",
                    }}
                    className="pl-5"
                  >
                    <li>
                      Para atender cualquier duda, consulta, queja, pregunta,
                      reclamación o solicitud sobre cualquier tipo de
                      información relacionada con los datos personales en
                      especial para ejercer el derecho de conocer, actualizar,
                      rectificar y/o suprimir información de datos personales,
                      el usuario podrá manifestar su solicitud mediante correo
                      electrónico tratamientodedatos@negociosnutibara.com. El
                      tratamiento de los datos personales que realiza
                      ADMINISTRACIÓN Y NEGOCIOS NUTIBARA S.A.S conforme esta
                      política, se hará con base a la norma, procedimientos e
                      instructivos adoptados por esta institución para el
                      cumplimiento de la legislación aplicable a la protección
                      de información personal, la cual puede ser consultada en
                      el Marco Legal de Aplicación de la Norma en Manejo y
                      Protección de Datos Personales.
                    </li>
                  </ol>
                </li>

                <li class="mt-5">
                  <b class="text-center">
                    <u>Modificaciones:</u>
                  </b>
                  <br />
                  <ol
                    style={{
                      listStyle: "circle",
                    }}
                    className="pl-5"
                  >
                    <li>
                      Administración y Negocios Nutibara S.A.S se reserva el
                      derecho de modificar la política de tratamiento de datos
                      en cualquier momento y sin previo aviso. Las
                      modificaciones realizadas entrarán en vigencia a partir de
                      su publicación y siempre estarán direccionadas al
                      cumplimiento de las exigencias de la normatividad
                      colombiana y a la protección de los datos de los
                      diferentes grupos de interés.
                    </li>
                  </ol>
                </li>
                <li class="mt-5">
                  <b class="text-center">
                    <u>Vigencia</u>
                  </b>
                  <br />
                  <ol
                    style={{
                      listStyle: "circle",
                    }}
                    className="pl-5"
                  >
                    <li>
                      La policía de datos de la empresa entra en vigencia a
                      partir del 01 de enero de 2017. Los datos personales que
                      sean recolectados serán almacenados por el tiempo que sea
                      necesario con el fin de dar cumplimiento a las finalidades
                      expuestas en política de tratamiento de datos.
                    </li>
                  </ol>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 footer-content">
              <div class="footer-colm-1">
                <h3 class="footer-logo">AYNN</h3>
                <p>
                  Empresa líder en calidad de servicio con cobertura nacional{" "}
                </p>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 footer-content">
              <div class="footer-colm-2">
                <h5 class="footer-colm-title">Nosotros</h5>
                <ul class="footer-nav">
                  <li>
                    <a href="#">Quiénes somos</a>
                  </li>
                  <li>
                    <a href="#">Contáctenos</a>
                  </li>
                  <li>
                    <a href="#"></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 footer-content">
              <div class="footer-colm-3">
                <h5 class="footer-colm-title">Soporte</h5>
                <ul class="footer-nav">
                  <li>
                    <Link to="/terminos">Términos y condiciones</Link>
                  </li>
                  <li>
                    <Link to="/politicas">Política tratamiento de datos</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 footer-content">
              <div class="footer-colm-4 footer-subscribe">
                <h5 class="footer-colm-title">Información de contacto</h5>

                <p>
                  <a href="mailto:contactenos@negociosnutibara.com">
                    contactenos@negociosnutibara.com
                  </a>
                </p>
                <p>
                  <a href="tel:+572515458">+572515458</a>
                </p>
              </div>
            </div>
          </div>
          <div class="border-shadow-2"></div>
          <div class="row">
            <div class="col-md-12">
              <div class="copyright-text text-center">
                <p>AYNN © 2020</p>
                <p>
                  Designed by <a href="#">Innode.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Terminos;

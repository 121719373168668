import React, { useState, useEffect } from 'react';
import {
    Link
} from "react-router-dom";
import { crearEmpleo, createStatus, getEmpleos } from '../../redux/empleo'
import { useSelector, useDispatch } from 'react-redux';

import { useHistory, useParams } from "react-router-dom";

const CrearEmpleo = () => {
    let history = useHistory();
    const create_status = useSelector((store) => store.oft.create_status)
    const lista_estado = useSelector((store) => store.bases.estados)
    const { token, user, isLogin } = useSelector((store) => store.auth);

    const [msj, setMsj] = useState("")
    const [estados, setEstados] = useState([])
    const [int, setInterval] = useState(1)
    const [data, setData] = useState({
        cargo: "",
        descripcion: "",
        requisitos: "",
        ciudad: "",
        oferta: "",
        fecha_publicacion: "",
        fecha_caducidad: "",
        estado_id: 0,
        correo: '',
        correos: [],
    });

    const [correos, setCorreos] = useState(['']);

    const { cargo, descripcion, requisitos, ciudad, oferta, fecha_publicacion, estado } = data;
    const dispatch = useDispatch();
    useEffect(() => {
        setEstados(lista_estado)
        if (create_status == 200 && token) {
            dispatch(getEmpleos(token))
            dispatch(createStatus(0))
            history.push("/admin")
        } else if (create_status == 1) {
            setMsj("Ocurrio un error al crear")

        }
    }, [create_status, lista_estado, token,correos])

    const onInputChange = e => {
        if (e.target.name == 'estado_id') {
            setData({ ...data, [e.target.name]: parseInt(e.target.value) });
        } else {
            setData({ ...data, [e.target.name]: e.target.value });

        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setData({ ...data, correos: correos });
        if (!cargo || !descripcion || !requisitos || !ciudad || !oferta || !fecha_publicacion) {
            setMsj("Completa todos los campos")
        } else {
           dispatch(crearEmpleo(data, token))

        }
    };
    const canceled = () => {
        if (token) {
            dispatch(getEmpleos(token))

        }
        dispatch(createStatus(0))
        history.push("/admin")
    }

    const addCorreo = () => {
        setCorreos([...correos,''])
    }
    const handleRemove = (count) => { 
        setInterval(int - 1);
        correos.pop(count);
        setCorreos([...correos])

    }


    const handleCorreo = (i,e) => {
        correos[i] = e.target.value;
        setCorreos([...correos])
    }
    return (
        <>
            <div className="section-header">
                <h1>Nueva Oferta</h1>
            </div>

            <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="card-header">
                            <h4>Datos de la oferta laboral</h4>
                        </div>
                        {
                            msj ? (<div class="alert alert-danger m-3">
                                {msj}
                            </div>) : (null)
                        }
                        <div className="card-body row">
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>cargo</label>
                                    <input type="text" name="cargo" className="form-control" required="" onChange={e => onInputChange(e)} />
                                </div>


                                <div className="form-group mb-0">
                                    <label>descripcion</label>
                                    <textarea 
                                        className="form-control" 
                                        name="descripcion" 
                                        required="" 
                                        onChange={e => onInputChange(e)}
                                        style={{ height: '200px' }}
                                    ></textarea>
                                </div>
                                <div className="form-group mb-0">
                                    <label>requisitos</label>
                                    <textarea 
                                        className="form-control" 
                                        name="requisitos" 
                                        required="" 
                                        onChange={e => onInputChange(e)}
                                        style={{ height: '200px' }}
                                        ></textarea>
                                </div>
                                <div className="form-group">
                                    <label>Estado</label>
                                    <select className="form-control" name="estado_id" onClick={e => onInputChange(e)}>
                                        {
                                            estados ? estados.map(e => <option value={e.id_estado} key={e.id_estado}>{e.descripcion}</option>) : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                                
                                <button type="button" className="btn btn-success" onClick={()=>addCorreo()}><i class="fas fa-plus"></i></button>
                                {
                                    correos.map((e, i) => {
                                        return <div className="form-group row ml-3">
                                            <label className="col-12">Correo</label>
                                            <input type="email" className="form-control col-8" name="correos" required="" onChange={e => handleCorreo(i,e)} />
                                            <button className="btn btn-danger col-1" type="button" onClick={()=>handleRemove(i)}><i class="fas fa-times"></i></button>

                                        </div>
                                    })
                                    
                                }
                               
                                <div className="form-group">
                                    <label>ciudad</label>
                                    <input type="text" className="form-control" name="ciudad" required="" onChange={e => onInputChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label>oferta</label>
                                    <input type="text" className="form-control" name="oferta" required="" onChange={e => onInputChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label>Fecha Publicación</label>
                                    <input type="datetime-local" name="fecha_publicacion" className="form-control" onChange={e => onInputChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label>Fecha Caducidad</label>
                                    <input type="datetime-local" name="fecha_caducidad" className="form-control" onChange={e => onInputChange(e)} />
                                </div>

                            </div>
                        </div>

                        <div className="card-footer text-right">
                            <button className="btn btn-success">Guardar</button>
                            <button type="button" className="btn btn-danger" onClick={() => canceled()}>Cancelar</button>

                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default CrearEmpleo;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './workStyle.css'
import moment from 'moment'
import img from '../img/works/drawkit-nature-man-colour.svg'
import { Form } from '../main/Form';


const WorkSection = () => {

    const empleos = useSelector((store) => store.oft.empleos);
    const enviar_postulacion = useSelector((store) => store.oft.enviar_postulacion)
    const [lista, setLista] = useState([])
    const [json, setJson] = useState({
        id_oferta_empleo: 0,
        estado: {
            id_estado: 0,
            descripcion: ''
        },
        fecha_creacion: '',
        fecha_modificacion: '',
        fecha_publicacion: '',
        fecha_caducidad: '',
        cargo: '',
        descripcion: '',
        ciudad: '',
        requisitos: '',
        oferta: '',
        usuario_creador: 0,
        usuario_modificador: 0,
        estado_id: 0

    })

    const [send, setSend] = useState(0)

    const [click, setClick] = useState(false)
    useEffect(() => {
        if (empleos) {
            setLista(empleos);
        }
        if (enviar_postulacion) {
            setSend(enviar_postulacion)
        }
    }, [empleos, enviar_postulacion])

    const time = (date) => {
        moment().locale('es')
        return moment(date, "YYYY-MM-DD hh:mm:ss").fromNow()
    }
    const handleSection = (json) => {
        setClick(false)
        setJson(json)
        setSend(0)
    }


    return (
        <section id="works" className="works-area" style={{marginTop:'80px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 section-title">
                        <h2>
                            Ofertas de<br />
                            empleo
                        </h2>
                        <div className="bar"></div>
                    </div>

                    <div className="col-md-6 scroll" style={{ overflow: 'auto', maxHeight: 500 }}>
                        {
                            lista.map((item, index) => {
                                return index != lista.length - 1 ? (
                                    <div key={index} >
                                        <div className="col-lg-12 col-md-12 col-sm-12 p-3 bg-white" style={{cursor:'pointer'}} onClick={() => handleSection(item)}>
                                            <div className="media box-shadow-only-hover hover-top border-all-1 border-color-gray ">
                                                <a className="overlay-link" href="#"></a>
                                                <div className="icon-50 bg-warning yellow-bg white-color border-radius-50 d-inline-block">
                                                </div>
                                                <div className="p-20px-l media-body">
                                                    <div className="badge badge-success mb-3">{item.ciudad}</div>
                                                    <h6 className="m-5px-tb">{item.cargo}</h6>
                                                    <p className="m-0px font-small">{time(item.fecha_publicacion)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div key={index}>
                                            <div className="col-lg-12 col-md-12 col-sm-12 p-3 bg-white"  style={{cursor:'pointer'}} onClick={() => handleSection(item)}>
                                                <div className="media box-shadow-only-hover hover-top border-all-1 border-color-gray ">
                                                    <a className="overlay-link" href="#"></a>
                                                    <div className="icon-50 bg-warning yellow-bg white-color border-radius-50 d-inline-block">
                                                    </div>
                                                    <div className="p-20px-l media-body">
                                                        <div className="badge badge-success mb-3">{item.ciudad}</div>
                                                        <h6 className="m-5px-tb">{item.cargo}</h6>
                                                        <p className="m-0px font-small">{time(item.fecha_publicacion)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ textAlign: 'center' }}>
                                            <div className="btn btn-link text-center">
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>


                    <div className="col-md-6 card card-primary scroll" style={{ overflow: 'auto', maxHeight: 530 }}>

                        {!click ? (
                            json.id_oferta_empleo != 0 ? (
                                <>
                                    <div className="card-header">
                                        <p className="pt-5"><h4>{json.cargo}</h4>
                                            <i className="fas fa-map-marker-alt pr-3"></i>{json.ciudad}<br />
                                        </p>
                                    </div>
                                    <div className="card-body pt-5">
                                        {`Fecha Publicación: ${json.fecha_publicacion}`}<br />
                                        {`Fecha Caducidad: ${json.fecha_caducidad}`}<br />
                                        <hr />
                                        {json.descripcion}
                                        <div className="title-card">Requisitos</div>
                                        <div >
                                        {json.requisitos}
                                        </div>

                                        <hr />
                                        {json.oferta}
                                    </div>
                                    <div className="card-footer text-right">
                                        <button className="btn btn-primary" onClick={() => setClick(true)}>Postular</button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <img className="img-fluid" src={img} alt="image" />
                                </>
                            )
                        ) : (
                            send == 0 ? (
                                <Form id_empleo={json.id_oferta_empleo} />

                            ) : (
                                send == 200 || send==201 ? (
                                    <div class="card">

                                        <div class="card-body">
                                            <div class="empty-state" data-height="400">
                                                <div class="empty-state-icon bg-success">
                                                    <i class="fas fa-check-circle"></i>
                                                </div>
                                                <h2>Postulacion enviada correctamente</h2>
                                                <p class="lead">
                                                    Nos comunicaremos con usted en breve
                                                </p>
                                                <button href="#" class="mt-4 bb" onClick={() =>setSend(0)}>Volver</button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (

                                    <div class="card">
                                        <div class="card-body">
                                            <div class="empty-state" data-height="400">
                                                <div class="empty-state-icon bg-danger">
                                                    <i class="fas fa-times"></i>
                                                </div>
                                                <h2>Ocurrio un error</h2>
                                                <p class="lead">
                                                    No se pudo procesar tu solicitud
                                                </p>
                                                <button href="#" class="mt-4 bb" onClick={() =>setSend(0)}>Volver</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        )

                        }
                    </div>

                </div>
            </div>
        </section>
    )
}

export default WorkSection;
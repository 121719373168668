import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendContact } from "../../redux/bases";
import { Link } from "react-router-dom";
const Footer = () => {
  const envio_contacto = useSelector((store) => store.bases.contacto);
  const dispatch = useDispatch();
  const [mensaje, setMensaje] = useState({ type: "", msj: "" });
  useEffect(() => {
    if (envio_contacto == 200) {
      setMensaje({ msj: "Se envió correctamente", type: "success" });
    } else if (envio_contacto == 1) {
      setMensaje({ msj: "Ocurrio un error al enviar", type: "danger" });
    }
  }, [envio_contacto]);
  const [data, setData] = useState({
    nombre: "",
    correo: "",
    asunto: "",
    mensaje: "",
  });

  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { nombre, mensaje, asunto, correo } = data;
    if (nombre == "" || mensaje == "" || asunto == "" || correo == "") {
      setMensaje({ msj: "Todos los campos son obligatorios", type: "danger" });
    } else {
      console.log(e);

      dispatch(sendContact(data));
    }
  };

  return (
    <>
      <section id="contact" class="contact-area mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12 section-title">
              <h2>Contáctenos</h2>
              <div class="bar"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-12">
              <div class="contact-info">
                <ul>
                  <li class="hob">
                    <div class="icon icon-bg-1">
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <span>Dirección</span>
                    Carrera 50 # 52-96, &amp; Medellín, Antioquia, Colombia
                  </li>
                  <li>
                    <div class="icon icon-bg-2">
                      <i class="fa fa-envelope"></i>
                    </div>
                    <span>Email</span>
                    <a href="#">contactenos@negociosnutibara.com</a>
                  </li>
                  <li>
                    <div class="icon icon-bg-3">
                      <i class="fa fa-phone"></i>
                    </div>
                    <span>Teléfono</span>
                    <a href="#">+572515458</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-7 col-md-12">
              <p class="form-message"></p>
              <br />
              <form
                class="contact-form form"
                onSubmit={(e) => handleSubmit(e)}
                method="POST"
              >
                <div class="controls">
                  <div class="row">
                    {mensaje.msj != "" ? (
                      <div class={`alert alert-${mensaje.type} m-3`}>
                        {mensaje.msj}
                      </div>
                    ) : null}
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group has-error has-danger">
                        <input
                          id="form_name"
                          type="text"
                          name="nombre"
                          onChange={(e) => onInputChange(e)}
                          placeholder="Tu Nombre"
                          required="required"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group has-error has-danger">
                        <input
                          id="form_email"
                          type="email"
                          name="correo"
                          onChange={(e) => onInputChange(e)}
                          placeholder="Tu Email"
                          required="required"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group has-error has-danger">
                        <input
                          id="form_subject"
                          type="text"
                          name="asunto"
                          onChange={(e) => onInputChange(e)}
                          placeholder="Tu Asunto"
                          required="required"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          id="form_message"
                          name="mensaje"
                          onChange={(e) => onInputChange(e)}
                          placeholder="Tu Mensaje"
                          rows="4"
                          required="required"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <button type="submit" class="button">
                        Enviar mensaje
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div class="responsive-map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.0918437435944!2d-75.5692196852789!3d6.251628327998651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4428f922b3a749%3A0x1506e6a85ee68c76!2sADMINISTRACION%20Y%20NEGOCIOS%20NUTIBARA!5e0!3m2!1ses-419!2sec!4v1623250479384!5m2!1ses-419!2sec"
          width="1920"
          height="600"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>

      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 footer-content">
              <div class="footer-colm-1">
                <h3 class="footer-logo">AYNN</h3>
                <p>
                  Empresa líder en calidad de servicio con cobertura nacional{" "}
                </p>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 footer-content">
              <div class="footer-colm-2">
                <h5 class="footer-colm-title">Nosotros</h5>
                <ul class="footer-nav">
                  <li>
                    <a href="#">Quiénes somos</a>
                  </li>
                  <li>
                    <a href="#">Contáctenos</a>
                  </li>
                  <li>
                    <a href="#"></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 footer-content">
              <div class="footer-colm-3">
                <h5 class="footer-colm-title">Soporte</h5>
                <ul class="footer-nav">
                  <li>
                    <Link to="/terminos">Términos y condiciones</Link>{" "}
                  </li>
                  <li>
                    <Link to="/politicas">Política tratamiento de datos</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 footer-content">
              <div class="footer-colm-4 footer-subscribe">
                <h5 class="footer-colm-title">Información de contacto</h5>

                <p>
                  <a href="mailto:contactenos@negociosnutibara.com">
                    contactenos@negociosnutibara.com
                  </a>
                </p>
                <p>
                  <a href="tel:+572515458">+572515458</a>
                </p>
              </div>
            </div>
          </div>
          <div class="border-shadow-2"></div>
          <div class="row">
            <div class="col-md-12">
              <div class="copyright-text text-center">
                <p>AYNN © 2020</p>
                <p>
                  Designed by <a href="#">Innode.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import {
    Link
} from "react-router-dom";
import { editEmpleo, editStatus,getEmpleos } from '../../redux/empleo'



const EditarEmpleo = () => {
    let history = useHistory();
    const { id } = useParams();
    const lista_empleos = useSelector((store) => store.oft.empleos)
    const edit_status = useSelector((store) => store.oft.edit_status)
    const { token, user, isLogin } = useSelector((store) => store.auth);

    const [lista, setLista] = useState([])
    const [msj, setMsj] = useState("")
    const dispatch = useDispatch();
    const [data, setData] = useState({
        cargo: "",
        descripcion: "",
        requisitos: "",
        ciudad: "",
        oferta: "",
        fecha_publicacion: "",
        fecha_caducidad: "",
        correos:[]
    });



    useEffect(() => {
        if (lista_empleos.length > 0) {
            const data_find = lista_empleos.find(empleo => empleo.id_oferta_empleo == id)
            setData(data_find)
        }
        if (edit_status == 200 &&token) {
            dispatch(getEmpleos(token))
            dispatch(editStatus(0))
            history.push("/admin")
        } else if (edit_status == 1) {
            setMsj("Ocurrio un error al editar")

        }
    }, [lista_empleos, edit_status,token]);
    const { cargo, descripcion, requisitos, ciudad, oferta, fecha_publicacion, fecha_caducidad,correos } = data;

    const onInputChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!cargo || !descripcion || !requisitos || !ciudad || !oferta || !fecha_publicacion || !token) {
            setMsj("Completa todos los campos")

        } else {
            if(token){
                dispatch(editEmpleo(id, data,token))

            }

        }
    };

    const canceled = () => {
        if(token){
            dispatch(getEmpleos(token))

        }
        dispatch(editStatus(0))
        history.push("/admin")
    }
    const addCorreo = () => {
        setData({...data,correos:[...correos,'']})

    }
    const handleRemove = (count) => { 
        correos.pop(count);
        setData({...data,correos:[...correos]})

    }


    const handleCorreo = (i,e) => {
        console.log(descripcion)
        correos[i] = e.target.value;
        setData({...data,correos:[...correos]})
    }

    return (
        <>
            <div className="section-header">
                <h1>{`Editar Oferta ${cargo}`} </h1>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="card-header">
                            <h4>Datos de la oferta laboral</h4>
                        </div>
                        {
                            msj ? (<div class="alert alert-danger m-3">
                                {msj}
                            </div>) : (null)
                        }
                        <div className="card-body row">

                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>cargo</label>
                                    <input type="text" name="cargo"
                                        className="form-control" required=""
                                        value={cargo}
                                        onChange={e => onInputChange(e)} />
                                </div>
                                <div className="form-group mb-0">
                                    <label>descripcion</label>
                                    <textarea className="form-control"
                                        name="descripcion" required=""
                                        value={descripcion}
                                        style={{ height: '200px' }}
                                        onChange={e => onInputChange(e)}></textarea>
                                </div>
                                <div className="form-group mb-0">
                                    <label>requisitos</label>
                                    <textarea className="form-control" name="requisitos"
                                        value={requisitos}
                                        style={{ height: '200px' }}
                                        required="" onChange={e => onInputChange(e)}></textarea>
                                </div>

                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                            <button type="button" className="btn btn-success" onClick={()=>addCorreo()}><i class="fas fa-plus"></i></button>


                                {
                                    correos.map((c, i) => {
                                        return <div className="form-group row ml-3">
                                        <label className="col-12">Correo</label>
                                        <input type="email" className="form-control col-8" name="correos" value={c} required="" onChange={e => handleCorreo(i,e)} />
                                        <button className="btn btn-danger col-1" type="button" onClick={()=>handleRemove(i)}><i class="fas fa-times"></i></button>

                                    </div>
                                    })
                                }
                                <div className="form-group">
                                    <label>ciudad</label>
                                    <input type="text" className="form-control"
                                        value={ciudad}

                                        name="ciudad" required="" onChange={e => onInputChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label>oferta</label>
                                    <input type="text" className="form-control"
                                        value={oferta}
                                        name="oferta" required="" onChange={e => onInputChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label>Fecha Publicación</label>
                                    <input type="datetime-local" name="fecha_publicacion"
                                        defaultValue={Date.parse(fecha_publicacion)}
                                        className="form-control" onChange={e => onInputChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label>Fecha Caducidad</label>
                                    <input type="datetime-local" name="fecha_caducidad" className="form-control" onChange={e => onInputChange(e)} />
                                </div>
                            </div>
                        </div>

                        <div className="card-footer text-right">
                            <button type="submit" className="btn btn-success">Guardar</button>

                            <button type="button" className="btn btn-danger" onClick={() => canceled()}>Cancelar</button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default EditarEmpleo;
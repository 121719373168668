import React from "react";

const Info = () => {
  return (
    <div  className="choose-area text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12 section-title">
            <h2>ADMINISTRACIÓN Y NEGOCIOS NUTIBARA S.A.S</h2>
            <div className="bar"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p style={{ fontSize: 20 }}>
              Proveer servicios empresariales que impulsen el logro de la estrategia de nuestros clientes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Info;

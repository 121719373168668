import axios from 'axios'

export default axios

/**@global CONSTANTES DE CONFIGURACION */



/** 
 * @constant 
 * @description Constantes de URL de la API
 */
export const URLAPI = 'https://bak.administracionynegociosnutibara.com'


export const EMPLEOS_ENDPOINT = '/empleos/'
export const ESTADOS_ENDPOINT = '/base/estados/'
export const EMPLEOS_NOAUTH_ENDPOINT = '/empleos/empleos/'
export const LOGIN_ENDPOINT = '/base/login/'
export const LOGOUT_ENDPOINT = '/base/logout/'
export const ENVIAR_POSTULACION_ENDPOINT = '/empleos/enviar-postulacion/'
export const POSTULANTES_ENDPOINT = '/empleos/postulantes/'
export const USUARIOS_ENDPOINT = '/base/usuarios/'
export const PASSWORD_RESET = '/base/password/reset/'
export const PASSWORD_CONFIRM = '/base/rest-auth/password/reset/confirm/'
export const CONTACT_ENDPOINT = '/base/contacto/'
export const CORREO_CONTACTO_ENDPOINT = '/base/correo/'
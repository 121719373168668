import React, { useEffect,useState } from 'react';
import SideBar from '../components/admin/sidebar'
import Nav from '../components/admin/nav'
import './admin.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import {getEmpleos,getPostulantes} from '../redux/empleo'
import {getEstados,getUsuarios,getCorreoContacto} from '../redux/bases';
import routes from '../components/admin/routes';
import { useSelector, useDispatch } from 'react-redux';

const Admin = () => {
    const { token, user, isLogin } = useSelector((store) => store.auth);

    const dispatch = useDispatch();
    const [perms,setPerms] = useState([]);
    useEffect(() => {
        if (token) {
            setPerms(token.permissions)
            dispatch(getEmpleos(token))
            dispatch(getEstados(token))
            dispatch(getPostulantes(token))
            dispatch(getUsuarios(token))
            dispatch(getCorreoContacto(token))

        }
    },[token])

    return (
        <Router>
            <div class="main-wrapper">
                <div class="navbar-bg"></div>
                <SideBar />
                <Nav />
                <div class="main-content">
                    <section class="section">
                        <Switch>
                            {
                                routes.map((route,key) => {
                                    return !route.is_header && perms.indexOf(route.permission) !== -1  ? (
                                        <Route path={route.path} exact={route.exact}  key={key}>{<route.component token ={token} ></route.component>} </Route>
                                    ) : (null)
                                })
                            }

                        </Switch>
                    </section>
                </div>
                <footer class="main-footer">
                    <div class="footer-left">  
                        Copyright &copy; 2021 <div class="bullet"></div> Design By <a href="#">Innode</a>
                    </div>
                  
                </footer>
            </div>
        </Router>

    )
}
export default Admin;
import React,{useEffect}from "react";
import Nav from "../components/main/nav";
import { Link } from "react-router-dom";
const Politicas = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav />
      <div className="container mt-5 pt-5" id="section">
        <div className="row mt-4   ">
          <div className="col-md-12">
            <h4>Política de tratamiento de datos</h4>
          </div>

          <div className="col-md-3 col-sm-12 col-xs-12 mt-4">
            <ul class="list-group">
              <li class="list-group-item" style={{
                  backgroundColor: "rgb(2, 66, 121)",
                  color: "#fff",
                }}>
                {" "}
                <Link to="/politicas" style={{
                    color: "#fff",
                }}>Política tratamiento de datos</Link>
              </li>
              <li
                class="list-group-item"
                
              >
                <Link to="/terminos" style={{
                    color: "black",
                }}>Términos y condiciones</Link>
              </li>
            </ul>
          </div>

          <div className="col mt-4 card">
            <p class="text-justify">
              La siguiente política de tratamiento de datos se desarrolla en
              cumplimiento de la Ley Estatutaria 1581 de 2012 por la cual se
              dictan disposiciones generales para la Protección de Datos
              Personales. En cumplimiento del artículo 9, se requiere la
              autorización previa e informada del titular para el tratamiento de
              sus datos personales. Por esta razón, mediante la aceptación de la
              presente política, todo titular que suministre información sobre
              sus datos personales, está consintiendo el tratamiento de sus
              datos por parte de <b>ADMINISTRACIÓN Y NEGOCIOS NUTIBARA S.A.S</b>, en
              los términos y condiciones regidos por la misma. Llevamos a cabo
              el tratamiento de datos personales de nuestros clientes,
              proveedores, empleados, socios y partes interesadas, los cuales se
              encuentran contenidos en bases de datos automatizadas y manuales y
              son tratados con fines administrativos, contables, tributarios,
              comerciales, laborales y de contacto para la gestión comercial,
              dentro del marco de cada contratación establecida. Para el
              ejercicio del Habeas Data, usted como titular del dato personal o
              quien demuestre un legítimo interés conforme lo señalado en la
              normatividad vigente, podrá hacerlo contactando a <b>ADMINISTRACIÓN Y
              NEGOCIOS NUTIBARA S.A.S</b>, a través del correo electrónico
              <b>tratamientodedatos@negociosnutibara.com</b> o dirigiendo una
              comunicación a la siguiente dirección física: carrera 50 # 52-96
              en la ciudad de Medellín. Quien ejerza el Derecho de habeas data
              deberá indicar con precisión los datos de contacto solicitados
              para efecto de tramitar y atender su solicitud y desplegar las
              cargas para el ejercicio de sus derechos. Recibida la solicitud de
              ejercicio de Habeas Data, <b>ADMINISTRACIÓN Y NEGOCIOS NUTIBARA S.A.S</b>
              tendrá un término de respuesta en el término legal de quince (15)
              días hábiles, el cual podrá ser prorrogado por ocho (8) días
              adicionales, previa comunicación a quien ha ejercido este derecho.
              En caso de no recibir la debida respuesta por parte de
              <b>ADMINISTRACIÓN Y NEGOCIOS NUTIBARA S.A.S</b>, podrá interponer queja
              ante la SIC en Bogotá. El tratamiento de los datos personales que
              realiza <b>ADMINISTRACIÓN Y NEGOCIOS NUTIBARA S.A.S</b> conforme esta
              política, se hará con base a la norma, procedimientos e
              instructivos adoptados por esta institución para el cumplimiento
              de la legislación aplicable a la protección de información
              personal, la cual puede ser consultada en el Marco Legal de
              Aplicación de la Norma en Manejo y Protección de Datos Personales.
              La presente política de tratamiento de datos se encuentra vigente
              desde 01 de enero de 2017.
            </p>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 footer-content">
              <div class="footer-colm-1">
                <h3 class="footer-logo">AYNN</h3>
                <p>
                  Empresa líder en calidad de servicio con cobertura nacional{" "}
                </p>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 footer-content">
              <div class="footer-colm-2">
                <h5 class="footer-colm-title">Nosotros</h5>
                <ul class="footer-nav">
                  <li>
                    <a href="#">Quiénes somos</a>
                  </li>
                  <li>
                    <a href="#">Contáctenos</a>
                  </li>
                  <li>
                    <a href="#"></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 footer-content">
              <div class="footer-colm-3">
                <h5 class="footer-colm-title">Soporte</h5>
                <ul class="footer-nav">
                  <li>
                    <Link to="/terminos">Términos y condiciones</Link>
                  </li>
                  <li>
                    <Link to="/politicas">Política tratamiento de datos</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 footer-content">
              <div class="footer-colm-4 footer-subscribe">
                <h5 class="footer-colm-title">Información de contacto</h5>

                <p>
                  <a href="mailto:contactenos@negociosnutibara.com">
                    contactenos@negociosnutibara.com
                  </a>
                </p>
                <p>
                  <a href="tel:+572515458">+572515458</a>
                </p>
              </div>
            </div>
          </div>
          <div class="border-shadow-2"></div>
          <div class="row">
            <div class="col-md-12">
              <div class="copyright-text text-center">
                <p>AYNN © 2020</p>
                <p>
                  Designed by <a href="#">Innode.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Politicas;

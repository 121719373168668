import React, { useEffect, useState } from 'react';
import { enviarPostulacion } from '../../redux/empleo'
import { useSelector, useDispatch } from 'react-redux';

export const Form = (props) => {

    const dispatch = useDispatch();
    const { id_empleo } = props;
    const [data, setData] = useState({
        nombre: '',
        apellido: '',
        telefono: '',
        correo: '',
        likedin: '',
        empleados_relacionados: false,
        parentezco: '',
        id_empleo: id_empleo
    })

    const [file, setFile] = useState(null);

    const enviar_Postulacion = (e) => {
        e.preventDefault();
        const { nombre, apellido, telefono, correo, likedin, empleados_relacionados, parentezco, id_empleo } = data;
        if (nombre === '' || apellido === '' || telefono === '' || correo === '') {
            alert('Por favor llena todos los campos');
        } else {
            if (file) {
                dispatch(enviarPostulacion(data, file));

            } else {
                alert('Por favor llena todos los campos');

            }

        }

    }

    const handleFile = (e) => {
        setFile(e.target.files[0]);

    }

    

    const onInputChange = e => {
        if (e.target.name == 'empleados_relacionados') {
            setData({ ...data, [e.target.name]: e.target.checked });

        } else {
            setData({ ...data, [e.target.name]: e.target.value });

        }

    };
    return (
        <div className="col-12 col-md-12 col-lg-12">
            <div className="card">
                <form onSubmit={(e) => enviar_Postulacion(e)}>
                    <div className="card-header">
                        <h4>Ingresa tus datos para postularte</h4>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <label>Nombres</label>
                            <input type="text" className="form-control" name="nombre" onChange={e => onInputChange(e)} tabindex="1" required />
                            <div className="invalid-feedback">
                                Ingresa tus nombres
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Apellidos</label>
                            <input type="text" className="form-control" name="apellido" onChange={e => onInputChange(e)} tabindex="2" required />
                            <div className="invalid-feedback">
                                Ingresa tus apellidos
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Teléfono</label>
                            <input type="text" className="form-control" name="telefono" onChange={e => onInputChange(e)} tabindex="3" required />
                            <div className="invalid-feedback">
                                Ingresa tu telefono
                            </div>
                        </div>
                        <div className="form-group mb-0">
                            <label>Correo</label>
                            <input type="email" className="form-control" name="correo" onChange={e => onInputChange(e)} tabindex="4" required />
                            <div className="invalid-feedback">
                                Ingresa tu correo
                            </div>
                        </div>
                        <div className="form-group mb-0">
                            <label>Linkeind (opcional)</label>
                            <input type="text" className="form-control" name="likedin" onChange={e => onInputChange(e)} tabindex="5" />

                        </div>
                        <div className="form-group ">
                            <input type="checkbox" className="form-check-input" name="empleados_relacionados" onChange={e => onInputChange(e)} tabindex="6" />
                            <label class="form-check-label">Colaboradores relacionados</label>

                        </div>
                        {
                            data.empleados_relacionados ? (
                                <div className="form-group mb-0">
                                    <label>Parentesco (opcional)</label>
                                    <input type="text" className="form-control" name="parentezco" onChange={e => onInputChange(e)} tabindex="7" />

                                </div>
                            ) : (null)
                        }

                        <div className="form-group mb-0">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" name="hoja_vida" accept=".docx,.pdf,.jpg,.png,.jpeg" onChange={e => handleFile(e)} tabindex="8" required />
                                <label className="custom-file-label" for="customFile">Hoja de vida</label>
                            </div>
                        </div>

                    </div>
                    <div className="card-footer text-right">
                        <button type="submit" className="btn btn-primary">Enviar postulación</button>
                    </div>
                </form>
            </div>
        </div>
    )

}
import React,{useState,useEffect} from 'react';
import nav from '../img/nav-logo.png'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import avatar from '../img/avatar-1.png'
import {logout} from '../../redux/auth';
import moment from 'moment'

const Nav = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const user = useSelector((store) => store.auth.user)
  const token = useSelector((store) => store.auth.token)

  const [auth, setAuth] = useState("")
  const [userName, setUserName] = useState("")

  useEffect(() => {
    if (!auth) {
      setAuth(token)
    }
    if(user){
      setUserName(user)
    }
  }, [token,user,auth])

  const logout_send = ()=>{
    dispatch(logout(auth));
    history.push('/');
  }
  const time = (date) => {
    moment().locale('es')
    return moment(date, "YYYY-MM-DD hh:mm:ss").fromNow()
}
    return (
        <nav className="navbar navbar-expand-lg main-navbar ">
        <form className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li><a href="#" data-toggle="sidebar" className="nav-link nav-link-lg"><i className="fa fa-bars" aria-hidden="true"></i></a></li>
            <li><a href="#" data-toggle="search" className="nav-link nav-link-lg d-sm-none"><i className="fa fa-search"></i></a></li>
          </ul>
         
        </form>
        <ul className="navbar-nav navbar-right">
          
          <li className="dropdown"><a href="#" data-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user">
            <img alt="image" src={avatar} className="rounded-circle mr-1"/>
            <div className="d-sm-none d-lg-inline-block">{userName}</div></a>
            <div className="dropdown-menu dropdown-menu-right">
              <div className="dropdown-title">{time(token.fecha)}</div>
              
              
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item has-icon text-danger" onClick={()=>logout_send()} >
                <i className="fa fa-sign-out-alt"></i> Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    )
}


export default Nav;

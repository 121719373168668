import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment'
import 'moment/locale/es';
import { URLAPI } from '../../config/index'
import Modal from '../../components/admin/modal';


const Postulantes = () => {

  const postulantes = useSelector((store) => store.oft.postulantes);
  const empleos = useSelector((store) => store.oft.empleos);

  let subtitle;

  const [lista, setLista] = useState([]);
  const [listaEmpleos, setListaEmpleos] = useState([])

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substr(0, 10);
  const [fecha, setFecha] = useState(date);
  const [cargo, setCargo] = useState("");
  const [check, setCheck] = useState(false);
  const [jsonSelection, setSelection] = useState({
    id_postulante: 0,
    fecha_creacion: '',
    fecha_modificacion: '',
    nombre: '',
    apellido: '',
    telefono: '',
    correo: '',
    likedin: null,
    empleados_relacionados: '',
    parentezco: null,
    hoja_vida: '',
    usuario_creador: 0,
    usuario_modificador: 0,
    postulaciones: []
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLista(postulantes);
    setListaEmpleos(empleos);
  }, [postulantes])
  const time = (date) => {
    moment().locale('es')
    return moment(date, "YYYY-MM-DD hh:mm:ss").fromNow()
  }


  const FiltrarCargo = (e) => {
    setCargo(parseInt(e.target.value));
    if (e.target.value == 0) {
      setLista(postulantes);
    } else {
      if (fecha) {
        let listado_ = []
        postulantes.forEach(element => {
          if (element.postulaciones.find(item => item.id_empleo == parseInt(e.target.value) && item.fecha_postulacion == fecha)) {
            listado_.push(element);
          }
        });

        setLista(listado_);
      } else {
        let listado_ = []
        postulantes.forEach(element => {
          if (element.postulaciones.find(item => item.id_empleo == parseInt(e.target.value))) {
            listado_.push(element);
          }
        });

        setLista(listado_);
      }



    }

  }

  const FiltarFecha = (e) => {
    if (cargo) {
      setFecha(e.target.value);
      let listado_ = []
      postulantes.forEach(element => {
        if (element.postulaciones.find(item => item.fecha_creacion == e.target.value && item.id_empleo == cargo)) {
          listado_.push(element);
        }
      });
      setLista(listado_);

    } else {
      setFecha(e.target.value);
      let listado_ = []
      postulantes.forEach(element => {
        if (element.postulaciones.find(item => item.fecha_creacion == e.target.value)) {
          listado_.push(element);
        }
      });
      setLista(listado_);
    }

  }


  const handleSelect = (data) => {
    setSelection(data);
    setOpen(true);
  }

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
        <button onClick={() => setOpen(!open)}>close</button>
        <div>I am a modal</div>
        <form>
          <input />
          <button>tab navigation</button>
          <button>stays</button>
          <button>inside</button>
          <button>the modal</button>
        </form>
      </Modal>
      <div class="section-header" id="modal-header">
        <h1>Lista de Postulantes</h1>
      </div>

      <div class="section-body">
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4>Listado</h4>
              <br />

            </div>




            <div class="card-body">
              <div class="row">
                <div class="custom-control custom-switch col-12 ml-3">
                  <input type="checkbox" onChange={e => {
                    setCheck(!check)
                    setFecha('')
                  }

                  } value={check} class="custom-control-input" id="customSwitch1" />
                  <label class="custom-control-label" for="customSwitch1">Filtrar por fecha</label>
                </div>
                {
                  check ? (<div class="form-group col-md-3 col-sm-12 col-xs-12">
                    <label>Fecha Postulación</label>
                    <input type="date" class="form-control" onChange={e => FiltarFecha(e)} value={fecha} />
                  </div>) : (null)
                }

                <div class="form-group col-md-4 col-sm-12 col-xs-12">
                  <label>Oferta</label>
                  <select class="custom-select" onChange={FiltrarCargo}>
                    <option value={0}>Todas</option>
                    {
                      listaEmpleos.map((empleo) => {
                        return <option value={empleo.id_oferta_empleo}>{empleo.cargo}</option>
                      })
                    }

                  </select>

                </div>

              </div>
              <div class="table-responsive">

                <table class="table table-bordered table-md" id="table">
                  <thead>

                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Telefono</th>
                      <th>Correo</th>
                      <th>Fecha Registro</th>
                      <th>Acciones</th>

                    </tr>
                  </thead>
                  <tbody>

                    {
                      lista.length > 0 ? (

                        lista.map((postulante, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{postulante.nombre} {postulante.apellido}</td>
                            <td>{postulante.telefono}</td>
                            <td>{postulante.correo}</td>
                            <td>{postulante.fecha_creacion} <br />{time(postulante.fecha_creacion)}</td>
                            <td>
                              <a href={URLAPI + postulante.hoja_vida} target="_blank" class="btn btn-icon btn-warning mr-6"><i class="fas fa-file-alt"></i></a>
                              <button class="btn btn-primary ml-3" onClick={() => handleSelect(postulante)}><i class="fas fa-eye"></i></button>


                            </td>

                          </tr>
                        ))

                      ) : (
                        <tr>
                          <td>No existen registros</td>
                        </tr>
                      )
                    }
                  </tbody>


                </table>

                <Modal show={open} handleClose={() => setOpen(!open)}>
                  <h5>{jsonSelection.nombre} {jsonSelection.apellido}</h5>
                  <div class="section-title"></div><br />
                  <p>
                    <b>Telefono: </b> {jsonSelection.telefono}<br />
                    <b>Correo: </b> {jsonSelection.correo}<br />
                    <b>LinkedIn: </b> {jsonSelection.likedin}<br />
                    <b>Empleados Relacionados: </b> {jsonSelection.empleados_relacionados}<br />
                    <b>Parentezco: </b> {jsonSelection.parentezco}<br />

                  </p>
                  <div class="section-title">Postulaciones</div>

                  <div class="table-responsive">
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Cargo</th>
                          <th scope="col">Fecha Creacion</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          jsonSelection.postulaciones.map((postulacion, index) => (
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>{postulacion.nombre}</td>
                              <td>{postulacion.fecha_creacion}</td>
                            </tr>
                          ))
                        }


                      </tbody>
                    </table>
                  </div>
                </Modal>



              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default Postulantes;
import React, { useState,useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { resetPassword } from '../redux/auth';
import { useHistory, useParams } from "react-router-dom";

const ResetPasswordPage = () => {

    let history = useHistory();
    const dispatch = useDispatch();
    
    const reset_confirm = useSelector((store) => store.auth.reset_confirm);

    const { uid, token } = useParams();

    const [msj, setMsj] = useState('');

    const [data,setData] = useState({
        new_password1:'', 
        new_password2:'', 
        uid:uid, 
        token:token
    });

    useEffect(() => {
        if(reset_confirm==1){
            setMsj('Ocurrio un error al reestablecer la contraseña')

        }else if(reset_confirm==200){
            history.push("/login")

        }
    }, [reset_confirm])


    const handleChange = (e) => {
        setData({
          ...data,
          [e.target.name]: e.target.value
        })
      }


      const handleSubmit = (e) => {
        e.preventDefault()
        const { new_password1, new_password2 } = data
        console.log(data)
        if (new_password1!='' && new_password2!='') {
          dispatch(resetPassword(data));
        } else {
          setMsj('Ingresa tu nueva contraseña')
        }
      }

    return(
        <section class="section">
      <div class="container mt-5">
        <div class="row">
          <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="login-brand">
              <img src="#" alt="logo" width="100" class="shadow-light rounded-circle"/>
            </div>

            <div class="card card-primary">
              <div class="card-header"><h4>Reestablecer contraseña</h4></div>

              <div class="card-body">
              {
                msj ? (<div className="alert alert-danger m-3">
                  {msj}
                </div>) : (null)
              }
                <form method="POST" onSubmit={(e) => handleSubmit(e)}>
                  

                  <div class="form-group">
                    <label for="password">Nueva Contraseña</label>
                    <input name="new_password1" type="password" class="form-control pwstrength" data-indicator="pwindicator" tabindex="2" required onChange={e => handleChange(e)}/>
                    <div id="pwindicator" class="pwindicator">
                      <div class="bar"></div>
                      <div class="label"></div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="password-confirm">Repite la Contraseña</label>
                    <input name="new_password2" type="password" class="form-control" tabindex="2" required onChange={e => handleChange(e)}/>
                  </div>

                  <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-lg btn-block" tabindex="4" >
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="simple-footer">
              Copyright &copy; Innode 2021
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}

export default ResetPasswordPage;
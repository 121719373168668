import React from "react";

const Services = () => {
  return (
    <section id="services" className="services-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 section-title">
            <h2>Valores organizacionales</h2>
            <div className="bar"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="services-item">
              <div className="services-icon">
                <i class="far fa-handshake"></i>
              </div>
              <div className="services-info">
                <h3>Civismo</h3>
                <p>
                  Considerando nuestra empresa como un escenario de vida,
                  promovemos en nuestros colaboradores una cultura de respeto
                  hacia los demás y el entorno natural. Fomentamos
                  comportamientos basados en la buena educación, urbanidad y
                  cortesía.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="services-item">
              <div className="services-icon">
                <i class="far fa-lightbulb"></i>
              </div>
              <div className="services-info">
                <h3>Innovación</h3>
                <p>
                  Buscamos constantemente la mejor manera de hacer las cosas,
                  implementando técnicas, procedimientos e iniciativas de
                  nuestros colaboradores, que permitan mejorar los procesos y el
                  crecimiento organizacional.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="services-item">
              <div className="services-icon">
                <i class="fa fa-check-square"></i>
              </div>
              <div className="services-info">
                <h3>Excelencia</h3>
                <p>
                  Desarrollamos prácticas de alta calidad en la gestión de
                  nuestros procesos, orientados hacia la satisfacción del
                  cliente y la obtención de los resultados, con vocación de
                  liderazgo, perseverancia y estandarización de procesos.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-1"></div>

          <div className="col-lg-4 col-md-5">
            <div className="services-item">
              <div className="services-icon">
                <i class="fas fa-users"></i>
              </div>
              <div className="services-info">
                <h3>Trabajo en equipo </h3>
                <p>
                  Promovemos la comunicación, coordinación y cohesión de
                  nuestros colaboradores, con la finalidad de aportar en la
                  consecución de los objetivos establecidos.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-5">
            <div className="services-item">
              <div className="services-icon">
                <i class="far fa-gem"></i>
              </div>
              <div className="services-info">
                <h3>Servicio</h3>
                <p>
                  Brindamos atención integral encaminada a cumplir las
                  expectativas de nuestros clientes internos y externos, con
                  actitud y agilidad.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-1"></div>
        </div>
      </div>
    </section>
  );
};

export default Services;

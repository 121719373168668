import React from 'react';

const Section1 = () => {
    return (
        <div id="choose" className="choose-area text-center">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 section-title">
                        <h2>Quiénes somos</h2>
                        <div className="bar"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 services-item-box">
                        <div className="services-item">
                            <div className="d-flex justify-content-center">
                                <div className="choose-icon icon-bg-1">
                                    <i className="pe-7s-refresh-2">
                                    </i>
                                </div>

                            </div>

                            <div className="services-info">
                                <h3>Nuestra historia</h3>
                                <p>En el año 2011, un grupo de empresarios se plantearon desarrollar un sistema eficiente de gestión en las áreas administrativas, finanzas, contabilidad, producción, comercial, control interno, cumplimientoy gestión humana; con la idea de brindar al mercado una propuesta diferente que pudiese prestar servicios de alta calidad, conformando así Administración y Negocios Nutibara. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 services-item-box">
                        <div className="services-item">
                            <div className="d-flex justify-content-center">

                                <div className="choose-icon icon-bg-2"><i className="pe-7s-graph3"></i></div>
                            </div>

                            <div className="choose-info">
                                <h3>Visión</h3>
                                <p>Ser referentes por la excelencia en nuestros servicios de asesoría y acompañamiento empresarial, con un enfoque profesional, humano y de sostenibilidad. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 services-item-box">
                        <div className="services-item">
                            <div className="d-flex justify-content-center">

                                <div className="choose-icon icon-bg-3"><i className="pe-7s-target"></i></div>
                            </div>

                            <div className="choose-info">

                                <h3>Misión</h3>
                                <p>Proveer servicios empresariales que impulsen el logro de la estrategia de nuestros clientes, acompañados de un equipo humano calificado, comprometido e innovador.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import {
    Link
} from "react-router-dom";
import {postUsuario,getUsuarios,setcreateUser} from '../../redux/bases'

const CrearUsuario = () => {
    let history = useHistory();

    const crear_usuario = useSelector((store) => store.bases.crear_usuario)
    const { token, user, isLogin } = useSelector((store) => store.auth);

    const [data,setData] = useState({
        username:'',
        first_name:'',
        last_name:'',
        email:'',
        password:'',
    })
    const [msj, setMsj] = useState("")
    const dispatch = useDispatch();

    
    useEffect(() => {
        if (crear_usuario == 200 && token) {
            dispatch(getUsuarios(token))

            history.push("/usuarios")
        } else if (crear_usuario == 1) {
            setMsj("Ocurrio un error al crear")

        }
    }, [crear_usuario,token])

    const {username, last_name, first_name, email} = data

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!username || !last_name || !first_name || !email) {
            setMsj("Completa todos los campos")

        } else {
            dispatch(postUsuario(data,token))

        }
    };

    const canceled = () => {
        if(token){
            dispatch(getUsuarios(token))

        }
        dispatch(setcreateUser(0))
        history.push("/admin")
    }

    const onInputChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    return(
        <>
            <div className="section-header">
                <h1>Nueva Oferta</h1>
            </div>

            <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="card-header">
                            <h4>Datos del Usuario</h4>
                        </div>
                        {
                            msj ? (<div class="alert alert-danger m-3">
                                {msj}
                            </div>) : (null)
                        }
                        <div className="card-body row">
                            <div className="col-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Usuario</label>
                                    <input type="text" name="username" className="form-control" required="" onChange={e => onInputChange(e)} />
                                </div>
                                <div className="form-group">
                                    <label>Nombre</label>
                                    <input type="text" name="first_name" className="form-control" required="" onChange={e => onInputChange(e)} />
                                </div>
                                
                                <div className="form-group mb-0">
                                    <label>Apellido</label>
                                    <input type="text" name="last_name" className="form-control" required="" onChange={e => onInputChange(e)} />
                                </div>
                                <div className="form-group mb-0">
                                    <label>Correo</label>
                                    <input type="text" name="email" className="form-control" required="" onChange={e => onInputChange(e)} />
                                </div>
                                <div className="form-group mb-0">
                                    <label>Contraseña</label>
                                    <input type="password" name="password" className="form-control" required="" onChange={e => onInputChange(e)} />
                                </div>
                            </div>
                            
                        </div>

                        <div className="card-footer text-right">
                            <button className="btn btn-success">Guardar</button>
                            <button type="button" className="btn btn-danger" onClick={() => canceled()}>Cancelar</button>

                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default CrearUsuario;
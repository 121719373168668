import React from "react";
import home from "../img/home-bg.jpg";
const Header = () => {
  return (
    <header
      id="home"
      class="home-area hero-equal-height section-padding overflow-hidden d-flex align-items-center"
      style={{
        background: `url(${home})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        backgroundSize: "cover",
        backgroundRepeatX: "no-repeat",
        backgroundRepeatY: "no-repeat",
        backgroundAttachment: "initial",
        backgroundOrigin: "initial",
        backgroundClip: "initial",
        backgroundColor: "initial",
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12">
            <div className="text-center home-content z-index position-relative">
              
            </div>
          </div>
        </div>
      </div>
      <svg
        fill="#FFFFFF"
        className="big-half-circle big-half-circle-1"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <path d="M0 100 C40 0 60 0 100 100 Z"></path>
      </svg>
    </header>
  );
};

export default Header;

import React, { useEffect } from "react";
import Nav from "../components/main/nav";
import Header from "../components/main/header";
import Section1 from "../components/main/section1";
import Services from "../components/main/Services";
import WorkSection from "../components/main/WorkSection";
import Clen from "../components/main/Clen";
import Footer from "../components/main/Footer";
import Info from "../components/main/Info";
import { useSelector, useDispatch } from "react-redux";
import { getEmpleosNoAuth } from "../redux/empleo";

const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmpleosNoAuth("", ""));
  }, []);

  return (
    <>
      <Nav />
      <Header />
      <Info />
      <Section1 />

      <Services />
      <WorkSection />

      <Footer />
    </>
  );
};

export default Index;

import axios, {
    URLAPI,ESTADOS_ENDPOINT,
    USUARIOS_ENDPOINT,CONTACT_ENDPOINT,CORREO_CONTACTO_ENDPOINT
} from '../config/index'

const init = {
    estados:[],
    create_status:0,
    edit_status:0,
    delete_status:0,
    error: {
        status: 0,
        message: null,
    },
    usuarios:[],
    contacto:0,
    crear_usuario:0,
    correo_contacto:0,
    correos_contacto:[],
}

/**@module Modulo Base */

/**@summary  Acciones para el reducer del modulo */


export const CREAR_ESTADO = 'CREAR_ESTADO'
export const EDITAR_ESTADO = 'EDITAR_ESTADO'
export const ELIMINAR_ESTADO = 'ELIMINAR_ESTADO'
export const ESTADOS = 'ESTADOS'
export const ERROR = 'ERROR'
export const USUARIOS = 'USUARIOS';
export const CREAR_USUARIO = 'CREAR_USUARIO';
export const SEND_CONTACT = 'SEND_CONTACT';
export const CREAR_CORREO_CONTACTO = 'SEND_CORREO_CONTACTO';
export const CORREOS_CONTACTO = 'CORREOS_CONTACTO';
/**
 * @method  estadosModule
 * @sumary Reducer del modulo base
 * @param {JSONObject} state - estado inicial del modulo
 * @param {JSONObject} data - datos a cambiar en el estado
 * @returns {JSONObject} estado actualizado
 */
export const estadosModule = (state = init, data) => {
    switch (data.type) {
        case CREAR_ESTADO:
            return {...state, create_status:data.payload}

        case EDITAR_ESTADO:
            return {...state, edit_status:data.payload}
        
        case ELIMINAR_ESTADO:
            return {...state, delete_status:data.payload}

        case ESTADOS:
            return {...state, estados:data.payload}
        
        case USUARIOS:
            return {...state, usuarios:data.payload}
        
        case CREAR_USUARIO:
            return {...state, crear_usuario:data.payload}
        case ERROR:
            return {...state, error:data.payload}
        case CONTACT_ENDPOINT:
            return {...state, contacto:data.payload}
        
        case CREAR_CORREO_CONTACTO:
            return {...state, correo_contacto:data.payload}

        case CORREOS_CONTACTO:
            return {...state, correos_contacto:data.payload}
        default:
            return {...state}
    }
}




export const getEstados = (token) => async (dispatch) => {

    try {
        const response = await axios.get(URLAPI + ESTADOS_ENDPOINT,{headers:{'Authorization':'Token '+token.token}});
        dispatch({ type: ESTADOS, payload: response.data })
    } catch (error) {
        dispatch({
            type: ERROR,
            payload: { status: 1, message: 'No se pudo conectar' }
        });
    }

}


export const putEstado = (id, estado) => async (dispatch) => {
    try {
        const response = await axios.put(URLAPI + ESTADOS_ENDPOINT + id, estado);
        dispatch({ type: EDITAR_ESTADO, payload: response.status })
    } catch (error) {
        dispatch({
            type: EDITAR_ESTADO,
            payload: 1
        });
    }
}



 export const postEstado = (estado) => async (dispatch) => {
    try {
        const response = await axios.post(URLAPI + ESTADOS_ENDPOINT, estado);
        dispatch({ type: CREAR_ESTADO, payload: response.status })
    } catch (error) {
        dispatch({
            type: CREAR_ESTADO,
            payload: 1
        });
    }
}


export const deleteEstado = (id) => async (dispatch) => {
    try {
        const response = await axios.delete(URLAPI + ESTADOS_ENDPOINT + id);
        dispatch({ type: ELIMINAR_ESTADO, payload: response.status })
    } catch (error) {
        dispatch({
            type: ELIMINAR_ESTADO,
            payload: 1
        });
    }
}


/**
 * @method  getUsuarios
 * @sumary Realiza un peticion a la api para obtener una lista de todos los usuarios 
 * @param {JSONObject} token - objeto de autenticacion
 * @return {void}
 * */
export const getUsuarios = (token) => async (dispatch) => {
    try {
        const response = await axios.get(URLAPI + USUARIOS_ENDPOINT,{headers:{'Authorization':'Token '+token.token}});
        dispatch({ type: USUARIOS, payload: response.data })
    } catch (error) {
        dispatch({
            type: ERROR,
            payload: { status: 1, message: 'No se pudo conectar' }
        });
    }
}

/**
 * @method  postUsuario
 * @sumary Realiza un peticion a la api para obtener una lista de todos los usuarios 
 * @param {JSONObject} usuario - objeto de usuario a crear
 * @param {JSONObject} token - objeto de autenticacion
 * @return {void}
 * */
export const postUsuario = (usuario,token) => async (dispatch) => {
    try {
        const response = await axios.post(URLAPI + USUARIOS_ENDPOINT, usuario,{headers:{'Authorization':'Token '+token.token}});
        dispatch({ type: CREAR_USUARIO, payload: response.status })
    } catch (error) {
        dispatch({
            type: CREAR_USUARIO,
            payload: 1
        });
    }
}


export const setcreateUser = (status) => async (dispatch) => {
    dispatch({ type: CREAR_USUARIO, payload: status })
}


/**
 * @method  sendContact
 * @sumary Realiza un peticion a la api enviar un correo de contacto
 * @param {JSONObject} data - objeto de contact
 * @return {void}
 * */
export const sendContact = (data) => async (dispatch) => {
    try {
        const response = await axios.post(URLAPI + CONTACT_ENDPOINT, data);
        dispatch({ type: SEND_CONTACT, payload: response.status })
    } catch (error) {
        dispatch({
            type: SEND_CONTACT,
            payload: 1
        });
    }
}

/**
 * @method  saveCorreoContacto
 * @sumary Realiza un peticion a la api crear un correo de contacto
 * @param {JSONObject} data - objeto de contact
 * @return {void}
 * */
export const saveCorreoContacto = (data,token) => async (dispatch) => {
    try {
        const response = await axios.post(URLAPI + CORREO_CONTACTO_ENDPOINT, data,{headers:{'Authorization':'Token '+token.token}});
        dispatch({ type: CREAR_CORREO_CONTACTO, payload: response.status })
    } catch (error) {
        dispatch({
            type: CREAR_CORREO_CONTACTO,
            payload: 1
        });
    }
}

/**
 * @method  editCorreoContacto
 * @sumary Realiza un peticion a la api edita un correo de contacto
 * @param {JSONObject} data - objeto de contact
 * @return {void}
 * */
export const editCorreoContacto = (id,data,token) => async (dispatch) => {
    try {
        const response = await axios.put(URLAPI + CORREO_CONTACTO_ENDPOINT+id, data,{headers:{'Authorization':'Token '+token.token}});
        dispatch({ type: CREAR_CORREO_CONTACTO, payload: response.status })
    } catch (error) {
        dispatch({
            type: CREAR_CORREO_CONTACTO,
            payload: 1
        });
    }
}


/**
 * @method  getCorreoContacto
 * @sumary Realiza un peticion a la api para obtener los correos de contacto
 * @param {JSONObject} data - objeto de contact
 * @return {void}
 * */
export const getCorreoContacto = (token) => async (dispatch) => {
    try {
        const response = await axios.get(URLAPI + CORREO_CONTACTO_ENDPOINT,{headers:{'Authorization':'Token '+token.token}});
        dispatch({ type: CORREOS_CONTACTO, payload: response.data })
    } catch (error) {
        dispatch({
            type: ERROR,
            payload: { status: 1, message: 'No se pudo conectar' }
        });
    }
}
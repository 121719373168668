import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { saveCorreoContacto, editCorreoContacto,getCorreoContacto } from '../../redux/bases'

const Correos = () => {

  const correos_contacto = useSelector((store) => store.bases.correos_contacto)
  const save_correo = useSelector((store) => store.bases.correo_contacto)
  const { token } = useSelector((store) => store.auth);

  const [correos, setCorreos] = useState([])
  const [data, setData] = useState({
    correo: ''
  })
  const [msj, setMsj] = useState({ msj: '', type: '' })

  useEffect(() => {
    setCorreos(correos_contacto)
    
   
    if (correos_contacto.length > 0) {
      setData({
        correo: correos_contacto[0].correo
      })
    }

    if (save_correo == 200) {
      setMsj({ msj: 'Correo guardado correctamente', type: 'success' })
      dispatch(getCorreoContacto(token))
    } else if (save_correo == 1) {
      setMsj({ msj: 'Ocurrio un error al guardar', type: 'error' })
    }
  }, [correos_contacto, token, save_correo])

  const dispatch = useDispatch()

  const onInputChange = e => {
    setData({
      correo: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (token) {
      if (correos.length > 0) {
        dispatch(editCorreoContacto(correos[0].id_correo, data, token))

      } else {
        dispatch(saveCorreoContacto(data, token))
      }
    }

  }


  return (
    <>
      <div class="section-header">
        <h1>Correo Contacto</h1>
      </div>

      <div class="section-body">
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
          <div class="card">
            <div class="card-header">


            </div>

            <form onSubmit={(e) => handleSubmit(e)}>
              {
                msj.type != '' ? (<div class={`alert alert-${msj.type} m-3`}>
                  {msj.msj}
                </div>) : (null)
              }
              <div class="card-body">
                <input type="email" value={data.correo} onChange={e => onInputChange(e)} class="form-control" placeholder="" />
              </div>
              <div className="card-footer text-right">
                <button type="submit" className="btn btn-success">Guardar</button>

              </div>
            </form>

          </div>
        </div>
      </div>
    </>
  )
}

export default Correos;